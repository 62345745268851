import React from "react";
import Layout from "../components/layout";

export default () => (
  <Layout>
    <div className="container">
      <h1>Resources</h1>

      <p>The below are some resources on getting started with web design.</p>

      <ul>
        <li>
          <a href="https://designforhackers.com/" className="link">
            Design for Hackers
          </a>{" "}
          a nice book on design giving clear principles
        </li>
        <li>
          <a
            href="https://medium.freecodecamp.org/a-developers-guide-to-web-design-for-non-designers-1f64ce28c38d"
            className="link"
          >
            A developer’s guide to web design for non-designers
          </a>
        </li>
      </ul>
    </div>
  </Layout>
);
